import './App.css';
import React, { FunctionComponent, useState } from "react"

class UserInfo {
  constructor(public name: string, public qualifications: string, public title: string, public landLineNumber: string, public mobileNumber: string, public email: string /*, skype: string?*/) { }
}

const users: UserInfo[] = [
  new UserInfo("Dr Peter S. Freedman", "MA BSc MB BS FRCP", "Medical Director", "+44 (0)131 202 0863", "+44 (0)7710 318 048", "peter.freedman@moletest-scotland.com"),
  new UserInfo("Christina Hamilton", "PhD", "Head of Clinical Research", "+44 (0)131 202 0863", "+44 (0)7775 262 384", "christina.hamilton@moletest-scotland.com"),
  new UserInfo("Joe Ferreira", "BSc Econ", "Marketing Director", "+44 (0)131 202 0863", "+44 (0)7771 664 004", "joe.ferreira@moletest-scotland.com"),
  new UserInfo("Ross Milligan", "MBE FIMI Hon.FIMI BSc", "Head of Medical Illustration", "+44 (0)131 202 0863", "+44 (0)7403 651 235", "ross.milligan@moletest-scotland.com"),
  new UserInfo("Roy G R Holland", "BCom", "Head of Business Development", "+44 (0)131 202 0863", "+44 (0)7885 464 100", "roy.holland@moletest-scotland.com"),
  new UserInfo("Geoff Siden", "", "Managing Director", "+44 (0)131 202 0863", "+44 (0)7860 409 220", "geoff.siden@moletest-scotland.com"),
  new UserInfo("Marcus Gould", "DPhil CBiol CSci", "Head of Regulatory Affairs & Quality Assurance", "+44 (0)131 202 0863", "+44 (0)7974 341 848", "marcus.gould@moletest-scotland.com"),
  new UserInfo("Bruce Murray", "BSc CEng MIMechE MIBC CMC", "Technical Director", "+44 (0)131 202 0863", "+44 (0)7850 165 080", "bruce.murray@moletest-scotland.com"),
  new UserInfo("Nick Ager", "BEng", "Head of IT", "+44 (0)131 202 0863", "+44 (0)7759 819 325", "nick.ager@moletest-scotland.com"),
]

const App: FunctionComponent = () => {
  const [selectedUser, setSelectedUser] = useState<UserInfo>(users[0])

  return (
    <div className="container">
      <p>How to integrate the signature:</p>
      <ul>
        <li><a href="macMail/macMail.html">Mac Mail</a></li>
        <li><a href="iPad/iPad.html">iPad Mail</a></li>
        <li><a href="gmail/gmail.html">Google web mail (Gmail)</a></li>
        <li><a href="outlook/outlook.html">Outlook (Windows)</a></li>
        <li><a href="thunderbird/thunderbird.html">Thunderbird</a></li>
      </ul>
      <br />
      <hr />
      <form className="">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="users">Your name:&nbsp;</label>
            <select id="users" onChange={(ev) => setSelectedUser(users[Number(ev.target.value)])}>
              {createSelectItems()}
            </select>
          </div>
        </div>
      </form>
      <br />
      <br />
      <br />
      <div style={{ fontFamily: 'Arial, Verdana, Geneva, Tahoma, sans-serif', width: '615px', fontSize: '9pt', lineHeight: '120%' }}>
        <div>
          <span><b>{selectedUser.name}</b>&nbsp;</span>
          <span>{selectedUser.qualifications}</span>
        </div>
        <div><b>{selectedUser.title}</b></div>
        <div >
          <br />
          Moletest (Scotland) Limited
          {renderNumber("M:", selectedUser.mobileNumber)}
          {renderNumber("T: ", selectedUser.landLineNumber)}
          <div><span><b>W:</b>&nbsp;</span><span><a href="https://nomela.com">nomela.com</a></span></div>
        </div>
      </div>
      <div style={{ borderBottom: '1px solid lightGrey', width: '609px', lineHeight: '3px' }}>&nbsp;</div>
      <table style={{ fontFamily: 'Arial, Verdana, Geneva, Tahoma, sans-serif', width: '615px' }}>
        <tbody>
          <tr >
            <td style={{paddingLeft: 0}}>
              <a href='https://player.vimeo.com/external/430695545.hd.mp4?s=48354523a69b8aca3f5d32398b72715c633f066d&profile_id=175'><img src={"https://signature.nomela.com/logoIPad.jpg"} srcSet={"https://signature.nomela.com/logoIPad@2x.jpg 2x"} alt="nomela logo" width={144} height={143} /></a>
            </td>
            <td>
              <div style={{ borderLeft: '1px solid lightGrey', height: '140px' }}></div>
            </td>
            <td style={{ verticalAlign: 'top' }}>
              <div style={{ fontSize: '13.5pt', lineHeight: '200%' }}><span style={{ color: '#008E92', fontWeight: 500 }}>The future of melanoma screening:</span> <a href="https://nomela.com" style={{ color: '#1F3251', textDecoration: 'none' }}>nomela.com</a>&nbsp;&nbsp;<a href='https://www.linkedin.com/company/moletest-scotland-limited/'><img src={"https://signature.nomela.com/linkedInIcon.jpg"} srcSet={"https://signature.nomela.com/linkedInIcon@2x.jpg 2x"} width={25} height={25} alt="linkedIn" /></a> <a href='https://twitter.com/moletest'><img src={"https://signature.nomela.com/twitterIcon.jpg"} srcSet={"https://signature.nomela.com/twitterIcon@2x.jpg 2x"} width={25} height={25} alt="twitter" /></a></div>
              <div style={{ fontSize: '7.35pt', lineHeight: '200%' }}>Developed by Moletest (Scotland) Limited. Second Floor, 24 Westover Road, Bournemouth BH1 2BZ</div>
              <div style={{ fontSize: '6pt', lineHeight: '130%', color: '#636363' }}>Reg office: 1 Exchange Crescent, Conference Square, Edinburgh EH3 8UL. Company No: SC478856 VAT: 230915920
              nomela&reg; is a registered trademark of Moletest (Scotland) Limited.<br />
              This email and any files transmitted with it are confidential and intended solely for the user of the individual or entity to whom
              they are addressed. If you have received this email in error please notify the system manager. This message contains
              confidential information and is intended only for the individual named. If you are not the named addressee you should not
              disseminate, distribute or copy this email. Please notify the sender immediately by email if you have received thie email by
              mistake and delete thie email from your system. If you are not the intended recipient you are notified that disclosing, copying,
              distributing or taking any action in reliance on the contents of this information is strictly prohibited.
            </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}



function createSelectItems(): JSX.Element[] {
  return users.map((user, index) => <option key={index} value={index}>{user.name}</option>)
}

function renderNumber(prefix: string, number: string): JSX.Element {
  if (number.length) {
    return <div>
      <span><b>{prefix}</b>&nbsp;</span>
      <span>{number}</span>
    </div>
  } else {
    return <span></span>
  }
}

export default App;
